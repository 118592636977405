import * as React from "react";
// @ts-ignore
import rocket from "../../assets/img/rocket.png";

interface IPageBoostPane {
    onPageBoostClicked: () => void;
}

export const PageBoostPane: React.StatelessComponent<IPageBoostPane> = (props: IPageBoostPane) => {
    return <div id="pageBoost_pane" className="pageBoost_pane overview_pane">
        <img id="pageBoost_pane_image" className="pageBoost_pane_image" src={rocket} />
        <div id="pageBoost_pane_content_container" className="pageBoost_pane_content_container">
            <h2 id="pageBoost_pane_header_text" className="pageBoost_pane_header_text">Don't forget to add Chrome Page Boost for <span className="pageBoost_pane_emphasized_text">FASTER</span> browsing speeds</h2>
            <div id="pageBoost_pane_spacer" className="pageBoost_pane_spacer" />
            <h3 id="pageBoost_pane_subheader_text" className="pageBoost_pane_subheader_text">It's included for free with your service</h3>
            <div id="pageBoost_pane_spacer" className="pageBoost_pane_spacer" />
            <button type="button" id="pageBoostButton" className="pageBoostButton appButton green" onClick={props.onPageBoostClicked}>Get Page Boost from Chrome Store - FREE</button>
        </div>
    </div>
};