import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { CreditCardUtils } from "../core/classes/CreditCardUtils";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { CreditCardData } from "../models/CreditCardData";
import { CreditCardTypes } from "../models/CreditCardTypes";



@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class CardForm extends React.Component<Partial<IComponentProps>>{
    CreditCardNumber: JQuery;
    SecurityCode: JQuery;
    ExpirationMonth: JQuery;
    ExpirationYear: JQuery;
    CCExpiration: JQuery;
    CreditCardType: JQuery;
    FormInputs: Array<JQuery>;

    public render() {

        const isLocked = this.props.viewStore.isUILocked;
        const paymentVM = this.props.viewStore.userPortalViewModel.AccountViewModel.Payment;

        let opts: Object = {};
        if (isLocked) {
            opts['disabled'] = 'disabled';
        }

        let expirationMonthDefault: Object = {};
        if (!(paymentVM.CardType === CreditCardTypes.None)) {
            expirationMonthDefault['defaultValue'] = CreditCardUtils.GetExpirationMonth(paymentVM).toString();
        }

        let expirationYearDefault: Object = {};
        if (!(paymentVM.CardType === CreditCardTypes.None)) {
            expirationYearDefault['defaultValue'] = CreditCardUtils.GetExpirationYear(paymentVM).toString();
        }

        let cardNumberPlaceholder: Object = {};
        if (!(paymentVM.CardType === CreditCardTypes.None)) {
            cardNumberPlaceholder['placeholder'] = `●●●●●●●●●●●●${paymentVM.LastFourOfCard}`;
        }

        let securityCodePlaceholder: Object = {};
        if (!(paymentVM.CardType === CreditCardTypes.None)) {
            securityCodePlaceholder['placeholder'] = '●●●';
        }

        return <div className="cardForm">
            <p className="cardFormLabel">Card Type:</p>
            <select {...opts} className="cardTypeSelect cardFormInput" defaultValue={CreditCardUtils.GetCardTypeString(paymentVM)}>
                <option className="cardTypeOption" value="Visa">Visa</option>
                <option className="cardTypeOption" value="MasterCard">MasterCard</option>
                <option className="cardTypeOption" value="Discover">Discover Card</option>
                <option className="cardTypeOption" value="American Express">American Express</option>
            </select>
            <div className="clear"></div>
            <p className="cardFormLabel">Card Number:</p>
            <input {...opts} type="text" name="CardNumber" {...cardNumberPlaceholder} className="cardNumberTextBox cardFormInput" />
            <div className="clear"></div>
            <p className="cardFormLabel">Security Code:</p>
            <input {...opts} type="text" name="SecurityCode" className="securityCodeTextBox cardFormInput" {...securityCodePlaceholder} />
            <div className="clear"></div>
            <p className="cardFormLabel">Expiration Date:</p>
            <select {...opts} name="ExpirationMonth" className="expiryMonthSelect cardFormInput" {...expirationMonthDefault}>
                <option className="expiryMonthOption" value="1">1</option>
                <option className="expiryMonthOption" value="2">2</option>
                <option className="expiryMonthOption" value="3">3</option>
                <option className="expiryMonthOption" value="4">4</option>
                <option className="expiryMonthOption" value="5">5</option>
                <option className="expiryMonthOption" value="6">6</option>
                <option className="expiryMonthOption" value="7">7</option>
                <option className="expiryMonthOption" value="8">8</option>
                <option className="expiryMonthOption" value="9">9</option>
                <option className="expiryMonthOption" value="10">10</option>
                <option className="expiryMonthOption" value="11">11</option>
                <option className="expiryMonthOption" value="12">12</option>
            </select>
            <p className="expiryDivider">/</p>
            <select {...opts} name="ExpirationYear" className="expiryYearSelect cardFormInput" {...expirationYearDefault}>
                {this.CreateExpirationYearOptions()}
            </select>
            <div className="clear"></div>
        </div>;
    }

    private CreateExpirationYearOptions(): JSX.Element[] {
        let options: JSX.Element[] = [];
        const start = new Date().getFullYear();
        for (let i = 0; i < 20; i++) {
            const year = start + i;
            options.push(<option id={`option_year_${year}`} className="expiryYearOption" value={year}>{year}</option>);
        }
        return options;
    }

    public componentDidMount() {
        this.Initialize();
    }

    private Initialize() {
        this.BindObjects();
        this.BindEvents();
        this.AttachPaymentFormatting();

    }

    private BindObjects() {
        this.CreditCardNumber = $(".cardNumberTextBox");
        this.SecurityCode = $(".securityCodeTextBox");
        this.ExpirationMonth = $(".expiryMonthSelect");
        this.ExpirationYear = $(".expiryYearSelect");
        this.CreditCardType = $(".cardTypeSelect");
        this.FormInputs = new Array(
            this.CreditCardNumber,
            this.SecurityCode,
            this.ExpirationMonth,
            this.ExpirationYear,
            this.CreditCardType
        );
    }

    private BindEvents() {

        const paymentVM = this.props.viewStore.userPortalViewModel.AccountViewModel.Payment;

        this.FormInputs.forEach((input) => {
            $(input).focusin((e) => { input.attr('placeholder', ''); });
            $(input).change((e) => { input.tooltipster('close'); });
        });

        this.CreditCardNumber.focusout((e) => {
            if (!(paymentVM.CardType === CreditCardTypes.None)) {
                this.CreditCardNumber.attr('placeholder', `●●●●●●●●●●●●${paymentVM.LastFourOfCard}`);
            }
        });

        this.SecurityCode.focusout((e) => {
            if (!(paymentVM.CardType === CreditCardTypes.None)) {
                this.SecurityCode.attr('placeholder', '●●●');
            }
        });
    }

    private AttachPaymentFormatting() {
        $('[data-numeric]').payment('restrictNumeric');
        this.CreditCardNumber.payment('formatCardNumber');
        this.SecurityCode.payment('formatCardCVC');

        this.CreditCardNumber.focusout((e) => {
            let cardNumber = $(e.currentTarget).val().toString();
            switch ($.payment.cardType(cardNumber)) {
                case 'visa':
                    this.CreditCardType.val('Visa');
                    break;
                case 'mastercard':
                    this.CreditCardType.val('MasterCard');
                    break;
                case 'discover':
                    this.CreditCardType.val('Discover');
                    break;
                case 'amex':
                    this.CreditCardType.val('American Express');
                    break;
            };
        });
    }

    public GetCCData(): CreditCardData {
        var retCC = new CreditCardData();

        retCC.CardNumber = this.CreditCardNumber.val().toString().replace(/\s+/g, '');
        let cardtype = $.payment.cardType(retCC.CardNumber);
        switch (cardtype) {
            case 'visa':
                retCC.Type = "Visa";
                break;
            case 'mastercard':
                retCC.Type = "MasterCard";
                break;
            case 'discover':
                retCC.Type = "Discover";
                break;
            case 'amex':
                retCC.Type = "AmericanExpress";
                break;
        }
        retCC.ExpirationMonth = this.ExpirationMonth.val().toString();
        retCC.ExpirationYear = this.ExpirationYear.val().toString();
        retCC.SecurityCode = this.SecurityCode.val().toString();
        return retCC;
    }

    public IsValid(): boolean {
        let cardNumber = this.CreditCardNumber.val().toString();
        let isValid = true;

        if ($.payment.validateCardNumber(cardNumber) != true) {
            this.CreditCardNumber.tooltipster('content', 'There is a problem with the credit card number provided.');
            this.CreditCardNumber.tooltipster('open');
            isValid = false;
        }

        let cardType = $.payment.cardType(cardNumber);

        switch (cardType) {
            case 'visa':
            case 'mastercard':
            case 'discover':
            case 'amex':
                break;
            default:
                this.CreditCardNumber.tooltipster('content', 'Only Visa, MasterCard or Discover cards are accepted.');
                this.CreditCardNumber.tooltipster('open');
                isValid = false;
        };

        if ($.payment.validateCardCVC(this.SecurityCode.val().toString(), cardType) != true) {
            this.SecurityCode.tooltipster('content', 'The security code provided is not valid.');
            this.SecurityCode.tooltipster('open');
            isValid = false;
        }

        if ($.payment.validateCardExpiry(this.ExpirationMonth.val().toString(), this.ExpirationYear.val().toString()) != true) {
            this.ExpirationYear.tooltipster('content', 'The expiration date is not valid');
            this.ExpirationYear.tooltipster('open');
            isValid = false;
        }

        if (isValid === false)
            return false;
        else {
            this.FormInputs.forEach((element, index, array) => {
                $(element).tooltipster('close');
            });
            return true;
        }
    }
}