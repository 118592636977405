import { configureScope } from "@sentry/core";
import { ICallbackPayload, IDispatcher, Page } from "driversupport.frontend.common";
import { inject, injectable, postConstruct } from "inversify";
import { action, autorun, runInAction } from "mobx";
import { IAuthApi } from "../../api/interfaces/IAuthApi";
import { IExceptionReporter } from "../../api/interfaces/IExceptionReporter";
import { ISupportApi } from "../../api/interfaces/ISupportApi";
import { IUIApi } from "../../api/interfaces/IUIApi";
import { GoogleAnalytics } from "../../core/classes/Analytics";
import { ErrorViewModel } from "../../core/classes/ErrorViewModel";
import { AppActionHelpers, FormatHelpers } from "../../core/classes/Helpers";
import { ViewUtils } from "../../core/classes/ViewUtils";
import { AppActions } from "../../core/enumerations/AppActions";
import { AppViews } from "../../core/enumerations/AppViews";
import { LoadActions } from "../../core/enumerations/LoadActions";
import { TYPES } from "../../core/enumerations/TYPES";
import { CancelFeedbackModel } from "../../models/CancelFeedbackModel";
import { ContactModel } from "../../models/ContactModel";
import { GetCheckoutUrlPayload } from "../../models/GetCheckoutUrlPayload";
import { ISubscriptionViewModel } from "../../models/ISubscriptionViewModel";
import { IUserPortalViewModel } from "../../models/IUserPortalViewModel";
import { LoginPayload } from "../../models/LoginPayload";
import { UpdatePaymentMethodPayload } from "../../models/UpdatePaymentMethodPayload";
import { IDhqUser } from "../../models/User";
import { UserDataUpdate } from "../../models/UserDataUpdate";
import { IViewStore } from "../../store/interfaces/IViewStore";
import { MachineViewModel } from "../../viewModels/MachineViewModel";
import { ViewModelBuilder } from "../../viewModels/ViewModelBuilder";
import { IViewObserver } from "../interfaces/IViewObserver";

declare var uiControllerPath: string;
declare var authControllerPath: string;
declare var cancelFlowOrderOverride: number[];
declare var portalBuildUrl: string;
declare var portalBuildChannel: string;
declare var cartUrl: string;
declare var _kmq;

@injectable()
export class ViewObserver implements IViewObserver {

    private readonly viewStore: IViewStore;
    private readonly authApi: IAuthApi;
    private readonly uiApi: IUIApi;
    private readonly supportApi: ISupportApi;
    private readonly exceptionReporter: IExceptionReporter;
    private readonly dispatcher: IDispatcher<AppActions>;

    constructor(@inject(TYPES.ViewStore) viewStore: IViewStore,
        @inject(TYPES.ExceptionReporter) exceptionReporter: IExceptionReporter,
        @inject(TYPES.AuthApi) authApi: IAuthApi,
        @inject(TYPES.UIApi) uiApi: IUIApi,
        @inject(TYPES.SupportApi) supportApi: ISupportApi,
        @inject(TYPES.Dispatcher) dispatcher: IDispatcher<AppActions>) {

        this.viewStore = viewStore;
        this.authApi = authApi;
        this.uiApi = uiApi;
        this.supportApi = supportApi;
        this.exceptionReporter = exceptionReporter;
        this.dispatcher = dispatcher;

        this.dispatcher.Register(this.dispatcherCallback.bind(this));

        // set exception context when we have a valid user
        autorun(() => {
            configureScope((scope) => {
                if (this.viewStore.user) {
                    scope.setUser({ "email": this.viewStore.user.Email });
                } else {
                    scope.setUser({ "id": "unknown user" });
                }
            });
        });

        window.addEventListener("cancelFlowOrderOverrideUpdated", this.cancelFlowOrderOverrideUpdated.bind(this));
    }

    // this is called when inversify creates an instance of this class
    @postConstruct()
    @action
    public async init() {
        try {
            this.viewStore.activeScreen = AppViews.Loading;

            this.getPageParameters();

            const loginStatus = await this.authApi.checkIsLoggedInAsync();

            if (loginStatus.isLoggedIn) {
                await this.loadUIDataAsync();
            }

            // set store
            runInAction(() => {
                this.viewStore.isLoggedIn = loginStatus.isLoggedIn;


                let activeScreen = null;

                if (this.viewStore.isLoggedIn) {

                    //logged in identify with email
                    _kmq.push(['identify', this.viewStore.user.Email]);

                    if (this.viewStore.initialScreen == null && this.viewStore.currentMachineIndex == null) {
                        // no current machine set -- short circuit to the pc selector in all cases
                        activeScreen = AppViews.PCSelector;
                    } else if (this.viewStore.initialScreen != null) {
                        // if an initial screen is set just go to it
                        activeScreen = this.viewStore.initialScreen;

                    } else {
                        // no initial screen set, but a machine has been selected
                        // so just go to overview
                        activeScreen = AppViews.Overview;
                    }
                } else {

                    //not logged in identify with uuid if it exists
                    if (this.viewStore.uuidQstring != null && this.viewStore.uuidQstring != "") {
                        _kmq.push(['identify', this.viewStore.uuidQstring]);
                    }

                    if (this.viewStore.initialScreen != null) {
                        if (ViewUtils.doesScreenRequireLogin(this.viewStore.initialScreen)) {
                            // an initial screen is set, but a login is required
                            // go to login
                            activeScreen = AppViews.Login;
                        } else {
                            // we have an initial screen that doesn't require
                            // to be logged in, so just go to that screen
                            activeScreen = this.viewStore.initialScreen;
                            this.viewStore.initialScreen = null;
                        }
                    } else {
                        // no initial screen set -- go to login since we're not already logged in
                        activeScreen = AppViews.Login;
                    }
                }

                this.viewStore.activeScreen = activeScreen;
                this.viewStore.isInitialized = true;
            });
        }
        catch (e) {
            this.setError(e);
        }
    }

    private dispatcherCallback(payload: ICallbackPayload<AppActions>): void {
        try {
            switch (payload.action.actionType) {
                case AppActions.CHANGE_ACTIVE_SUBSCRIPTION:
                    this.handleChangeActiveSubscription(payload.action.data as string);
                    break;
                case AppActions.GET_EXPRESS_CHECKOUT_URL:
                    this.handleGetExpressCheckoutUrlAsync(payload.action.data as GetCheckoutUrlPayload);
                    break;
                case AppActions.LOGIN:
                    this.handleLoginAsync(payload.action.data as LoginPayload);
                    break;
                case AppActions.LOGOFF:
                    this.handleLogoffAsync();
                    break;
                case AppActions.REFRESH_UI:
                    this.handleRefreshUIAsync(payload.action.data as AppViews);
                    break;
                case AppActions.RESUME_SUBSCRIPTION:
                    this.handleResumeSubscriptionAsync(payload.action.data as string);
                    break;
                case AppActions.SELECT_ACTIVE_SCREEN:
                    this.handleSelectActiveScreen(payload.action.data as AppViews);
                    break;
                case AppActions.UPDATE_PAYMENT_METHOD:
                    this.handleUpdatePaymentMethod(payload.action.data as UpdatePaymentMethodPayload);
                    break;
                case AppActions.UPDATE_USER_INFO:
                    this.handleUpdateUserInfo(payload.action.data as UserDataUpdate);
                    break;
                case AppActions.CONTACT:
                    this.handleContact(payload.action.data as ContactModel);
                    break;
                case AppActions.CONTACT_FORM_BACK:
                    this.handleContactFormBack();
                    break;
                case AppActions.DEACTIVATE_CURRENT_MACHINE:
                    this.handleDeactivateCurrentMachine();
                    break;
                case AppActions.LAUNCH_PAGE_BOOST:
                    this.handleLaunchPageBoost();
                    break;
                case AppActions.SUBMIT_CANCEL_FEEDBACK:
                    this.handleSubmitCancelFeedback(payload.action.data as CancelFeedbackModel);
                    break;
                case AppActions.DOWNLOAD_DSONE:
                    this.handleDownloadDsOne();
                    break;
                case AppActions.OPEN_INVOICE:
                    this.handleOpenInvoice(payload.action.data as string);
                    break;
                case AppActions.SELECT_ACTIVE_MACHINE:
                    this.handleSelectActiveMachine(payload.action.data as MachineViewModel)
                    break;
                case AppActions.LAUNCH_CART:
                    this.handleLaunchCart();
                    break;
                case AppActions.SHOW_ERROR_MODAL:
                    this.handleShowErrorModal();
                    break;
                case AppActions.CLOSE_ERROR_MODAL:
                    this.handleCloseErrorModal();
                    break;
            }
        } catch (ex) {
            const error = new Error("Error processing app action in DispatcherCallback() in ViewObserver");
            this.exceptionReporter.ReportCriticalException(error);
        }
    }

    @action
    private handleCloseErrorModal() {
        this.viewStore.isInErrorState = false;
        this.viewStore.isUILocked = false;
    }

    @action
    private handleShowErrorModal() {
        this.viewStore.isInErrorState = true;
        this.viewStore.isUILocked = true;
    }

    private handleLaunchCart() {
        const url = cartUrl + `?regKey=${this.viewStore.user.RegistrationKey}`;
        window.open(url, "_blank");
    }

    @action
    private handleSelectActiveMachine(activeMachine: MachineViewModel) {
        if (activeMachine != null) {
            const index = this.viewStore.allMachines.indexOf(activeMachine);
            if (index != null && index >= 0) {
                this.viewStore.currentMachineIndex = index;
            }
        }
    }

    private handleOpenInvoice(invoiceUrl: string) {
        GoogleAnalytics.TrackEvent("AccountPortal", "Billing History", "Invoice Clicked");
        window.open(invoiceUrl, "_blank");
    }

    @action
    private async handleSubmitCancelFeedback(payload: CancelFeedbackModel) {
        try {
            this.viewStore.isUILocked = true;
            this.viewStore.contactError = null;

            const response = await this.supportApi.submitCancelFeedback(payload);

            runInAction(() => {
                if (response.isSuccessfulAction) {
                    //this.viewStore.activeScreen = AppViews.MessageSent;
                } else {
                    if (response.statusCode === 401) {
                        this.handleUnauthorized();
                        return;
                    }
                    throw new Error(response.errorMessage);
                }
            });
        }
        catch (e) {
            this.exceptionReporter.ReportException(e);
            runInAction(() => {
                this.viewStore.contactError = new ErrorViewModel("Error submitting contact message");
            });
        }
        finally {
            runInAction(() => {
                this.viewStore.isUILocked = false;
            });
        }
    }

    @action
    private handleDownloadDsOne() {
        window.open(this.viewStore.userPortalViewModel.AutoActivatedInstallerUrl, "_blank");
    }

    @action
    private handleLaunchPageBoost() {
        window.open("https://chrome.google.com/webstore/detail/driversupport-page-boost/meijgcolbddgmiifkdefanfpdhhhdoaa", "_blank");
    }

    @action
    private async handleDeactivateCurrentMachine() {

        this.viewStore.activeScreen = AppViews.Loading;

        const currentMachine = this.viewStore.allMachines[this.viewStore.currentMachineIndex];
        let currentLicenseActivation = currentMachine.licenseActivation;
        currentLicenseActivation.IsEnabled = false;

        try {

            const response = await this.uiApi.updateLicenseActivationAsync(currentLicenseActivation);
            runInAction(() => {
                if (response.isSuccessfulAction) {
                    this.viewStore.currentMachineIndex = null;
                    this.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.REFRESH_UI, AppViews.PCSelector));
                } else {
                    if (response.statusCode === 401) {
                        this.handleUnauthorized();
                        return;
                    }
                    throw new Error(response.errorMessage);
                }
            });
        } catch (e) {
            this.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.Overview));
            this.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.SHOW_ERROR_MODAL, null));
        }
    }


    @action
    private cancelFlowOrderOverrideUpdated() {
        this.viewStore.cancelFlowOrder = cancelFlowOrderOverride;
    }

    @action
    private getPageParameters() {
        this.viewStore.initialScreen = AppViews[Page.GetPageHashValue()];
        this.viewStore.loadAction = LoadActions[Page.GetParameterByName("loadAction")];
        this.viewStore.localHttpServerPort = Page.GetParameterByName("ap");
        this.viewStore.uuidQstring = Page.GetParameterByName("UUID");

        const machineIndex = Page.GetParameterByName("machineIndex");
        if (machineIndex != null && machineIndex !== "") {
            this.viewStore.currentMachineIndex = +machineIndex;
        }
    }

    @action
    private handleContactFormBack() {
        this.viewStore.contactError = null;
        this.handleSelectActiveScreen(AppViews.Support);
    }

    @action
    private async handleContact(payload: ContactModel) {
        try {
            this.viewStore.isUILocked = true;
            this.viewStore.contactError = null;

            const response = await this.supportApi.submitContactRequest(payload);

            runInAction(() => {
                if (response.isSuccessfulAction) {
                    this.viewStore.activeScreen = AppViews.MessageSent;
                } else {
                    if (response.statusCode === 401) {
                        this.handleUnauthorized();
                        return;
                    }
                    throw new Error(response.errorMessage);
                }
            });
        }
        catch (e) {
            this.exceptionReporter.ReportException(e);
            runInAction(() => {
                this.viewStore.contactError = new ErrorViewModel("Error submitting contact message");
            });
        }
        finally {
            runInAction(() => {
                this.viewStore.isUILocked = false;
            });
        }
    }

    @action
    private async handleGetExpressCheckoutUrlAsync(payload: GetCheckoutUrlPayload) {
        //clear out old error
        this.viewStore.paypalError = null;

        try {
            GoogleAnalytics.TrackEvent("AccountPortal", "Update Payment", "Continue To Paypal Requested");

            this.viewStore.isUILocked = true;

            const response = await this.uiApi.getExpressCheckoutUrlAsync(payload.subscriptionId, payload.registrationKey, payload.returnAction);

            if (response.isSuccessfulAction) {
                GoogleAnalytics.TrackEvent("AccountPortal", "Update Payment", "Continue To Paypal Redirect");
                this.redirectToPaypal(response.checkoutUrl);
            } else {
                if (response.statusCode === 401) {
                    this.handleUnauthorized();
                    return;
                }
                throw new Error("Error getting paypal url");
            }

        }
        catch (e) {
            this.exceptionReporter.ReportException(e);
            runInAction(() => {
                this.viewStore.isUILocked = false;
            });
            this.setPaypalError(new ErrorViewModel("Error redirecting to Paypal"));
        }
    }

    @action
    private handleUnauthorized() {
        this.viewStore.isUILocked = false;
        // if unauthorized just redirect to Login
        this.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.LOGOFF, null));
    }

    @action
    private async handleRefreshUIAsync(returnScreen: AppViews) {

        try {
            this.viewStore.activeScreen = AppViews.Loading;
            // get user info
            const userResponse = await this.authApi.getUserAsync();
            if (!userResponse.isSuccessfulAction) {
                if (userResponse.statusCode === 401) {
                    this.handleUnauthorized();
                    return;
                }
                this.exceptionReporter.ReportException(new Error(userResponse.errorMessage));
                throw new Error("Error loading user");
            }
            const newUser = userResponse.userInfo;

            // get portal view model 
            const portalResponse = await this.uiApi.getUserPortalViewModelAsync(newUser.ZuoraAccountID, newUser.RegistrationKey);
            if (!portalResponse.isSuccessfulAction) {
                if (portalResponse.statusCode === 401) {
                    this.handleUnauthorized();
                    return;
                }
                this.exceptionReporter.ReportException(new Error(portalResponse.errorMessage));
                throw new Error(`Error loading user view model for user ${newUser.Email}`);
            }
            const portalVM = portalResponse.userPortalVM;

            // update the store
            runInAction(() => {
                this.viewStore.isUILocked = false;

                this.setUserPortalViewModel(portalVM);

                this.viewStore.user = newUser;

                //identify with email of logged in user
                _kmq.push(['identify', this.viewStore.user.Email]);

                //we need to update active subscription because in the case of a renewal the old subscription will no longer be available
                this.viewStore.activeSubscriptionId = portalVM.AccountViewModel.Subscriptions[0].SubscriptionId;

                if (this.viewStore.currentMachineIndex == null) {
                    this.viewStore.activeScreen = AppViews.PCSelector;
                    return;
                }

                // redirect to 'return screen' if set
                if (returnScreen) {
                    this.viewStore.activeScreen = returnScreen;
                } else {
                    // if all else fails default to the overview
                    this.viewStore.activeScreen = AppViews.Overview;
                }
            });
        }
        catch (e) {
            this.exceptionReporter.ReportException(new Error(`Error loading view model data for user ${this.viewStore.user.Email}`));
            this.redirectToErrorPage();
        }
    }

    @action
    private async handleResumeSubscriptionAsync(subscriptionId: string) {

        try {
            const cancelDate = new Date(this.viewStore.activeSubscription.CancellationTime);
            const now = new Date();
            let milliseconds = now.getTime() - cancelDate.getTime();
            const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
            milliseconds -= days * (1000 * 60 * 60 * 24);
            const hours = Math.floor(milliseconds / (1000 * 60 * 60));
            milliseconds -= hours * (1000 * 60 * 60);
            const minutes = Math.floor(milliseconds / (1000 * 60));
            const timeString = FormatHelpers.leftPadZeros(days, 2) + ":" + FormatHelpers.leftPadZeros(hours, 2) + ":" + FormatHelpers.leftPadZeros(minutes, 2);
            _kmq.push(['record', "Reactivated"], { "Time Since Cancel": timeString });

            // show loading screen
            this.viewStore.activeScreen = AppViews.Loading;
            // lock app
            this.viewStore.isUILocked = true;
            // await resume
            const status = await this.uiApi.resumeSubscriptionAsync(subscriptionId, this.viewStore.user.RegistrationKey, this.viewStore.uuidQstring);

            if (status.isSuccessfulAction) {
                //wait a bit before we refresh.  zuora appears to have some timing issues if you try to get info immediately after resuming
                setTimeout(async () => { await this.handleRefreshUIAsync(AppViews.SubscriptionDetail); }, 3000);
            } else {
                if (status.statusCode === 401) {
                    this.handleUnauthorized();
                    return;
                }

                if (status.errorMessage === "Transaction Declined") {
                    await this.handleRefreshUIAsync(AppViews.PaymentDeclined);
                }
                if (status.errorMessage === "Subscription has already been resumed" || status.errorMessage === "Subscription is locked for processing") {
                    await this.handleRefreshUIAsync(AppViews.SubscriptionDetail);
                } else {
                    throw new Error(status.errorMessage);
                }
            }
        } catch (e) {
            this.exceptionReporter.ReportException(new Error(`Error resuming subscription for user ${this.viewStore.user.Email}`));
            this.redirectToErrorPage();
        }
        finally {
            runInAction(() => {
                this.viewStore.isUILocked = false;
            });
        }
    }

    @action
    private async handleUpdatePaymentMethod(payload: UpdatePaymentMethodPayload) {

        GoogleAnalytics.TrackEvent("AccountPortal", "Update Payment", "Update Payment Sent");
        try {
            this.viewStore.isUILocked = true;

            const status = await this.uiApi.updatePaymentMethodAsync(payload.accountKey, payload.creditCard, payload.customerBilling);

            if (status.isSuccessfulAction) {
                GoogleAnalytics.TrackEvent("AccountPortal", "Update Payment", "Update Payment Complete");

                if (this.viewStore.activeScreen === AppViews.UpdatePaymentAndResume) {
                    await this.handleResumeSubscriptionAsync(this.viewStore.activeSubscriptionId);
                } else {
                    await this.handleRefreshUIAsync(AppViews.SubscriptionDetail);
                }
            } else {

                if (status.statusCode === 401) {
                    this.handleUnauthorized();
                    return;
                }
                throw new Error(status.errorMessage);
            }
        }
        catch (e) {
            this.exceptionReporter.ReportException(e);
            this.setUpdatePaymentMethodError(new ErrorViewModel("Error updating payment method"));
        }
        finally {
            runInAction(() => {
                this.viewStore.isUILocked = false;
            });
        }
    }

    @action
    private async handleUpdateUserInfo(userInfo: UserDataUpdate) {

        GoogleAnalytics.TrackEvent("AccountPortal", "Update Personal Info", "Update Personal Info Confirmed");
        try {
            this.viewStore.updatePersonalInfoError = null;
            this.viewStore.isUILocked = true;

            const response = await this.authApi.updateUserInfoAsync(userInfo);

            runInAction(() => {
                if (response.isSuccessfulAction) {
                    this.viewStore.user = response.userInfo;
                    this.viewStore.activeScreen = AppViews.Overview;
                } else {
                    if (response.statusCode === 401) {
                        this.handleUnauthorized();
                        return;
                    }
                }
            });
        }
        catch (e) {
            this.setUserUpdateError(new ErrorViewModel(e.message));
        }
        finally {
            runInAction(() => {
                this.viewStore.isUILocked = false;
            });
        }
    }

    @action
    private handleChangeActiveSubscription(newSubscriptionId: string) {
        this.viewStore.activeSubscriptionId = newSubscriptionId;
    }

    private handleSelectActiveScreen(screen: AppViews) {
        this.redirect(screen);
    }

    @action
    private async handleLoginAsync(loginPayload: LoginPayload) {

        this.viewStore.loginError = null;

        try {
            runInAction(() => {
                this.viewStore.isLoggingIn = true;
            });
            const loginStatus = await this.authApi.loginAsync(loginPayload.username, loginPayload.password, loginPayload.rememberMe);
            if (loginStatus.isSuccessfulAction) {
                runInAction(() => {
                    this.viewStore.activeScreen = AppViews.Loading;
                    this.viewStore.isLoggingIn = false;
                    this.viewStore.isLoggedIn = true;
                });

                await this.loadUIDataAsync();

                runInAction(() => {

                    //logged in identify with email
                    _kmq.push(['identify', this.viewStore.user.Email]);

                    if (this.viewStore.initialScreen) {
                        this.viewStore.activeScreen = this.viewStore.initialScreen;
                        this.viewStore.initialScreen = null;
                    } else {
                        this.viewStore.activeScreen = AppViews.PCSelector;
                    }
                });
            } else {
                if (loginStatus.errorMessage == "Password Expired") {
                    //they need to reset their password
                    window.location.href = `${authControllerPath}expiredpassword?email=${loginPayload.username}`;
                }

                // log error for login page
                runInAction(() => {
                    this.viewStore.loginError = new ErrorViewModel(loginStatus.errorMessage);
                });
            }
        }
        catch (e) {
            this.exceptionReporter.ReportException(e);
            runInAction(() => {
                this.viewStore.loginError = new ErrorViewModel("There was an error while logging in");
            });
        }
        finally {
            runInAction(() => {
                this.viewStore.isLoggingIn = false;
            });
        }
    }

    @action
    private async handleLogoffAsync() {
        try {
            if (this.viewStore.isUILocked) {
                return;
            }

            this.viewStore.initialScreen = this.viewStore.activeScreen;
            this.viewStore.activeScreen = AppViews.Loading;

            const logoffVM = await this.authApi.logOffAsync();
            if (logoffVM.isSuccessfulAction) {

                runInAction(() => {
                    this.viewStore.activeScreen = AppViews.Login;
                    this.viewStore.isLoggedIn = false;
                    this.viewStore.user = null;
                    this.viewStore.userPortalViewModel = null;
                    this.viewStore.activeSubscriptionId = null;
                    this.viewStore.currentMachineIndex = null;
                });

                //const activeScreen = AppViews.Login;
                //const title = `Driver Support Account Portal ${AppViews[activeScreen]}`;
                //const hash = `#${AppViews[activeScreen]}`;
                //history.replaceState({ activeScreen: AppViews.Login, activeSubscriptionId: null, activeMachineIndex: null }, title, window.location.pathname + hash);
                // window.location.search = "";
            }
        }
        catch (ex) {
            this.setError(ex);
        }
    }

    @action
    private async loadUIDataAsync() {

        const userResponse = await this.authApi.getUserAsync();
        if (!userResponse.isSuccessfulAction) {
            if (userResponse.statusCode === 401) {
                this.handleUnauthorized();
                return;
            }
            if (userResponse.errorMessage === "V10 Redirect") {
                window.location.href = `${uiControllerPath}unsupportedaccount/`;
            }
            if (userResponse.errorMessage === "SolveIQ Redirect") {
                window.location.href = `${uiControllerPath}unsupportedaccountsolveiq/`;
            }
            this.exceptionReporter.ReportException(new Error(userResponse.errorMessage));
            throw new Error("Error loading user");
        }
        const user = userResponse.userInfo;

        //set window variables for external integrations
        window["ds_username"] = `${user.FirstName} ${user.LastName}`;
        window["ds_email"] = user.Email;
        window["ds_phone"] = user.PhoneNumber;

        const licenseResponse = await this.authApi.getUserLicenseAsync(user.RegistrationKey);
        if (!licenseResponse.isSuccessfulAction) {
            if (licenseResponse.statusCode === 401) {
                this.handleUnauthorized();
                return;
            }
            this.exceptionReporter.ReportException(new Error(licenseResponse.errorMessage));
            throw new Error("Error retrieving user license");
        }
        const userLicense = licenseResponse.license;
        // we only need this license to check the free trial property
        if (userLicense.FreeTrial) {
            this.redirectToFreeTrial();
            return;
        }

        const portalResponse = await this.uiApi.getUserPortalViewModelAsync(user.ZuoraAccountID, user.RegistrationKey);
        if (!portalResponse.isSuccessfulAction) {
            if (portalResponse.statusCode === 401) {
                this.handleUnauthorized();
                return;
            }
            this.exceptionReporter.ReportException(new Error(portalResponse.errorMessage));
            throw new Error(`Error loading user view model for user ${user.Email}`);
        }
        const userPortalVM = portalResponse.userPortalVM;
        console.log("Parallel Time: " + userPortalVM.UserMachinesViewModel.ParallelQueryTime);
        console.log("Remaining Time: " + userPortalVM.UserMachinesViewModel.RemainingUUIDQueryTime);
        console.log("Parallel Output: " + userPortalVM.UserMachinesViewModel.ParallelOutput);

        runInAction(() => {

            if (user.HasRecurringSubscription) {
                let activeSubscription: string;

                switch (this.viewStore.loadAction) {
                    case LoadActions.ResumeSubscription:
                        //if we have a resume subscription load action we want to select the first suspended subscription
                        let suspendedSub = userPortalVM.AccountViewModel.Subscriptions.find((sub: ISubscriptionViewModel) => sub.SubscriptionStatus.toLowerCase() === "suspended")
                        if (suspendedSub) {
                            this.viewStore.activeSubscriptionId = suspendedSub.SubscriptionId;
                            this.viewStore.activeScreen = AppViews.UpdatePaymentAndResume;
                        }
                        else {
                            activeSubscription = this.getCurrentSubscriptionId(userPortalVM);
                            this.viewStore.activeSubscriptionId = activeSubscription;
                        }
                        break;
                    default:
                        //no load action do default behavior
                        activeSubscription = this.getCurrentSubscriptionId(userPortalVM);
                        //this.setState({ activeScreen: activeScreen, activeSubscriptionId: activeSubscription, isLoggedIn: true });
                        this.viewStore.activeSubscriptionId = activeSubscription;
                        break;
                }
            }
            else {
                //this is a term customer no need for subscription id
                //this.setState({ activeScreen: activeScreen, isLoggedIn: true });
            }

            this.setUser(user);
            this.setUserPortalViewModel(userPortalVM);
        });
    }

    private getCurrentSubscriptionId(portalVM: IUserPortalViewModel): string {
        let activeSubscriptionId: string;
        activeSubscriptionId = Page.GetParameterByName("sid");
        if (!activeSubscriptionId || !portalVM.AccountViewModel.Subscriptions.find((sub: ISubscriptionViewModel) => sub.SubscriptionId == activeSubscriptionId)) {
            activeSubscriptionId = portalVM.AccountViewModel.Subscriptions[0].SubscriptionId;
        }
        return activeSubscriptionId;
    }


    @action
    private redirect(to: AppViews) {
        //reset cancel flow
        this.viewStore.currentCancelStep = 1;
        this.viewStore.activeScreen = to;
    }

    @action
    private setUserPortalViewModel(vm: IUserPortalViewModel) {
        this.viewStore.userPortalViewModel = vm;
        this.viewStore.allMachines = [];

        if (vm.UserMachinesViewModel != null && vm.UserMachinesViewModel.UserMachines != null) {
            for (let m of vm.UserMachinesViewModel.UserMachines) {
                this.viewStore.allMachines.push(ViewModelBuilder.buildMachineVM(m));
            }
        }
    }

    @action
    private setIsLoggingIn(val: boolean) {
        this.viewStore.isLoggingIn = val;
    }

    @action
    private setLoginError(message: string) {
        this.viewStore.loginError = new ErrorViewModel(message);
    }

    @action
    private setUserUpdateError(error: ErrorViewModel) {
        this.viewStore.updatePersonalInfoError = error;
    }

    @action
    private setUpdatePaymentMethodError(error: ErrorViewModel) {
        this.viewStore.updatePaymentError = error;
    }

    @action
    private setPaypalError(error: ErrorViewModel) {
        this.viewStore.paypalError = error;
    }

    @action
    private setInitialized() {
        this.viewStore.isInitialized = true;
    }

    @action
    private setUser(user: IDhqUser) {
        this.viewStore.user = user;
    }

    @action
    private setIsLoggedIn(isLoggedIn: boolean) {
        this.viewStore.isLoggedIn = isLoggedIn;
    }

    @action
    private setError(error: Error) {
        this.exceptionReporter.ReportException(error);
        this.viewStore.isError = true;
    }

    private redirectToFreeTrial() {
        // do somethign here
    }

    private redirectToErrorPage() {

    }

    private redirectToPaypal(url: string) {
        window.location.href = url;
    }


}