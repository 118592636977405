import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { ContactForm } from "./ContactForm";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class ContactView extends React.Component<Partial<IComponentProps>>{
    private supportTopic: string;

    constructor(props) {
        super(props);

        this.supportTopic = this.getTopicFromActiveScreen();
    }

    public render() {
        try {
            const topic = this.supportTopic;
            return <div className="wrapper">
                <div className="appPanel contactFormWrapper defaultMaterial">
                    <ContactForm topic={topic} />
                    <div className="supportInfo">
                        <p>Email Support: <a href="mailto:support@driversupport.com">support@driversupport.com</a></p>
                    </div>
                </div>
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering ContactView Screen - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private getTopicFromActiveScreen(): string {

        switch (this.props.viewStore.activeScreen) {
            case AppViews.QuestionAboutBill:
                return "Question about bill";
            case AppViews.InstallDriverProblem:
                return "How do I install my driver";
            case AppViews.SoftwareInstallProblem:
                return "Software will not install";
            case AppViews.DriverQuestionForm:
                return "Driver will not update / Device specific problem";
            case AppViews.ContactForm:
                return "Contact Us";
        }
    }
}