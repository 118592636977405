import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { UpdatePaymentMethodPayload } from "../models/UpdatePaymentMethodPayload";
import { BillingAddressForm, PaymentCountries } from "./BillingAddressForm";
import { CardForm } from "./CardForm";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class UpdatePaymentCC extends React.Component<Partial<IComponentProps>>{
    private billingAddressForm: React.RefObject<any>;
    private cardForm: React.RefObject<any>;

    constructor(props) {
        super(props);
        this.cardForm = React.createRef();
        this.billingAddressForm = React.createRef();

        this.IsStateRequired = this.IsStateRequired.bind(this);
    }

    public render() {

        const error = this.props.viewStore.updatePaymentError;
        const isLocked = this.props.viewStore.isUILocked;

        return <form className="updatePaymentMethodCCForm">

            <BillingAddressForm ref={this.billingAddressForm} />

            <CardForm ref={this.cardForm} />

            {isLocked &&
                <div className="updatingPaymentMethodBox miniLoader">
                    <div className="loaderImage" />
                    <p className="updatingPaymentMethodText miniLoaderText">Updating payment method...</p>
                </div>
            }
            {error &&
                <div className="paymentErrorBox miniErrorWrapper">
                    <div className="errorImage" />
                    <p className="paymentErrorText miniErrorText">Error updating payment.  Please check your payment details and try again.</p>
                </div>
            }
            <img className="sslNotifyImage" />
            <button type="submit" className="cardFormUpdateButton appButton blue">
                <p className="appButton__buttonText">Update Payment Method</p>
            </button>
            <button type="button" className="cancelButton appButton grey" onClick={this.onCancelClicked.bind(this)}>
                <p className="appButton__buttonText">Cancel</p>
            </button>
        </form>;
    }

    public componentDidMount() {
        this.Initialize();
    }

    private Initialize() {
        $('.updatePaymentMethodCCForm input[type="text"], .updatePaymentMethodCCForm input[type="email"], .updatePaymentMethodCCForm input[type="checkbox"],  .updatePaymentMethodCCForm select').tooltipster({
            trigger: 'custom', // default is 'hover' which is no good here
            onlyOne: false, // allow multiple tips to be open at a time
            position: 'right', // display the tips to the right of the element
            theme: 'error-tooltip'
        });
        this.ValidationInit();
    }

    private onCancelClicked() {
        if (this.props.viewStore.isUILocked) {
            return;
        }
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.SubscriptionDetail);
        this.props.dispatcher.DispatchAction(action);
    }

    private IsStateRequired() {
        return this.billingAddressForm.current.wrappedInstance.selectedCountryType === PaymentCountries.Canada ||
            this.billingAddressForm.current.wrappedInstance.selectedCountryType === PaymentCountries.USA;
    }

    private ValidationInit() {

        $('.updatePaymentMethodCCForm').validate({
            errorPlacement: function (error, element) {
                var lastError = $(element).data('lastError'),
                    newError = $(error).text();

                $(element).data('lastError', newError);

                if (newError !== '') {
                    $(element).tooltipster('content', newError);
                    $(element).tooltipster('open');
                }
            },
            rules: {
                FirstName: { required: true, maxlength: 100 },
                LastName: { required: true, maxlength: 100 },
                Address: { required: true, maxlength: 255 },
                City: { required: true, maxlength: 40 },
                State: { required: () => this.IsStateRequired },
                ZipCode: { required: true, maxlength: 20 },
                Country: "required",
                CardNumber: "required",
                SecurityCode: "required"
            },
            submitHandler: () => {
                try {
                    if (this.props.viewStore.isUILocked) { return; }
                    if (!this.cardForm.current.wrappedInstance.IsValid()) { return; }
                    this.SendPaymentMethodUpdate();
                } catch (ex) {
                    //TODO: if this fails we need to send an error
                }
            },
            success: function (label, element) {
                $(element).tooltipster('close');
            },
            messages: {
                FirstName: "Please enter a valid first name",
                LastName: "Please enter a valid last name",
                Address: "Please enter a valid address",
                City: "Please enter a valid city",
                ZipCode: "Please enter a valid zip",
                CardNumber: "Please enter your card number",
                SecurityCode: "Please enter your security code"
            }
        });
    }

    private SendPaymentMethodUpdate() {

        const payload = new UpdatePaymentMethodPayload();
        payload.accountKey = this.props.viewStore.user.ZuoraAccountID;
        payload.creditCard = this.cardForm.current.wrappedInstance.GetCCData();
        payload.customerBilling = this.billingAddressForm.current.wrappedInstance.LoadBillingInfo();
        payload.customerBilling.Email = this.props.viewStore.user.Email;

        const action = AppActionHelpers.CreateAction(AppActions.UPDATE_PAYMENT_METHOD, payload);
        this.props.dispatcher.DispatchAction(action);
    }
}