import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { IComponentProps } from "../main/interfaces/IComponentProps";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class RegistrationKeyArea extends React.Component<Partial<IComponentProps>, undefined>{
    constructor(props) {
        super(props);

        this.onActivateClicked = this.onActivateClicked.bind(this);
    }

    public render() {
        try {
            const regKey = this.props.viewStore.user.RegistrationKey;

            return <div className="registrationKeyArea">
                <h2 className="panelSubTitle">Registration Key</h2>
                <div className="keyWrapper">
                    <p className="registrationKey">{regKey}</p>
                    <div className="spacer" />
                    <button type="button" className="activateLink linkButton" onClick={this.onActivateClicked}>Download Driver Support</button>
                </div>
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering RegistrationKeyArea - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private onActivateClicked(e) {
        e.preventDefault();
        const action = AppActionHelpers.CreateAction(AppActions.DOWNLOAD_DSONE, null);
        this.props.dispatcher.DispatchAction(action);
    }
}