import { inject, observer } from "mobx-react";
import * as React from "react";
// @ts-ignore
import addAnotherPc from "../../assets/img/addAnotherPc.png";
// @ts-ignore
import defaultPC from "../../assets/img/defaultPC.png";
// @ts-ignore
import dsLogo from "../../assets/img/dsLogoLarge.png";
// @ts-ignore
import bottomLeft from "../../assets/img/pcSelectorBottomLeft.png";
// @ts-ignore
import topRight from "../../assets/img/pcSelectorTopRight.png";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { AddAnotherPcView } from "../main/components/AddAnotherPcView";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { MachineViewModel } from "../viewModels/MachineViewModel";
import { PCCarousel } from "./PCCarousel";

interface IPCSelectorState {
    showAddAnotherPcView: boolean;
}

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter,
}))
@observer
export class PCSelectorView extends React.Component<Partial<IComponentProps>, IPCSelectorState>
{
    constructor(props) {
        super(props);

        this.state = { showAddAnotherPcView: false };

        this.ItemClicked = this.ItemClicked.bind(this);
        this.AddAnotherPcClicked = this.AddAnotherPcClicked.bind(this);
        this.CloseAddAnotherPcClicked = this.CloseAddAnotherPcClicked.bind(this);
        this.GetMoreLicensesClicked = this.GetMoreLicensesClicked.bind(this);
        this.ContinueToPortalClicked = this.ContinueToPortalClicked.bind(this);
        this.LogoffClicked = this.LogoffClicked.bind(this);
        this.MyAccountClicked = this.MyAccountClicked.bind(this);
        this.DownloadPortalBuildClicked = this.DownloadPortalBuildClicked.bind(this);
    }

    public render() {
        const customerName = this.props.viewStore.user.FirstName;
        const remainingLicenses = this.props.viewStore.userPortalViewModel.LicenseUsageViewModel.LicensesRemaining;
        const enabledMachines = this.props.viewStore.allMachines.filter(m => m.licenseActivation.IsEnabled);

        try {
            return <div className="pcSelectorContainer">
                <div className="logo">
                    <img className="logoImage" id="logoImage" src={dsLogo} />
                    <div className="logoText"><span className="driver">Driver</span> Support|One</div>
                </div>
                <div className="menuContainer">
                    <a className="myAccountLink" id="pcSelectorMyAccountLink" onClick={this.MyAccountClicked}>My Account</a>
                    <a className="logoffLink" id="pcSelectorLogoffLink" onClick={this.LogoffClicked}>Log Off</a>
                </div>
                <img className="topRight" id="topRight" src={topRight} />
                <img className="bottomLeft" id="bottomLeft" src={bottomLeft} />
                <h1 className="pcSelectorTitle">Welcome {customerName}! Select your PC to get started</h1>
                <div className="licenseStatusContainer" id="licenseStatusContainer">

                    {remainingLicenses !== 1 &&
                        <h3 className="licenses_remaining" id="licenses_remaining">You have <span className="licenses_remaining_value" id="licenses_remaining_value">{remainingLicenses}</span> licenses remaining</h3>
                    }
                    {remainingLicenses === 1 &&
                        <h3 className="licenses_remaining" id="licenses_remaining">You have <span className="licenses_remaining_value" id="licenses_remaining_value">{remainingLicenses}</span> license remaining</h3>
                    }
                    {remainingLicenses === 0 &&
                        <button className="getMoreLicensesButton linkButton" id="getMoreLicensesButton" onClick={this.GetMoreLicensesClicked}>Get More Licenses</button>
                    }
                </div>

                {enabledMachines.length > 3 ? (
                    <div className="carouselContainer" id="carouselContainer">
                        <PCCarousel carouselData={this.props.viewStore.allMachines.filter(m => m.licenseActivation.IsEnabled)} onPCSelected={(m) => this.ItemClicked(m)} />
                        <button className="carouselAddAnotherPcButton appButton blue" id="carouselAddAnotherPcButton" onClick={this.AddAnotherPcClicked}>Add Another PC</button>
                    </div>
                ) : (
                        <div className="pcSelectorItems">
                            {enabledMachines.map((machine, i) => {
                                return <PCSelectorItemView key={i} machine={machine} addAnotherPc={false} onClickHandler={() => this.ItemClicked(machine)} />;
                            })}
                            <PCSelectorItemView machine={null} addAnotherPc={true} onClickHandler={this.AddAnotherPcClicked} />
                        </div>
                    )}

                {this.state.showAddAnotherPcView &&
                    <AddAnotherPcView onCloseClickedCallback={this.CloseAddAnotherPcClicked} onDownloadClickedCB={this.DownloadPortalBuildClicked} />
                }

                {(!enabledMachines || enabledMachines.length == 0) &&
                    <div className="continueToOverview" id="continueToOverview">
                        <a className="continueToOverviewLink" id="continueToOverviewLink" onClick={this.ContinueToPortalClicked}>Continue To Portal</a>
                    </div>
                }
            </div>;
        } catch (ex) {
            let outerex = new Error(`Error Rendering PCSelectorView - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            return null;
        }
    }

    private GetMoreLicensesClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.LAUNCH_CART, null);
        this.props.dispatcher.DispatchAction(action);
    }

    private ItemClicked(machine: MachineViewModel) {
        this.props.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_MACHINE, machine));
        this.props.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.Overview));
    }

    private AddAnotherPcClicked() {
        this.setState({ showAddAnotherPcView: true });
    }

    private CloseAddAnotherPcClicked() {
        this.setState({ showAddAnotherPcView: false });
    }

    private ContinueToPortalClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.Overview);
        this.props.dispatcher.DispatchAction(action);
    }

    private LogoffClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.LOGOFF, null);
        this.props.dispatcher.DispatchAction(action);
    }


    private MyAccountClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.SubscriptionDetail);
        this.props.dispatcher.DispatchAction(action);
    }

    private DownloadPortalBuildClicked() {
        this.props.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.DOWNLOAD_DSONE, null));
    }

}

interface IPCSelectorItemViewState {
    imageLoadError: boolean
}

export interface IPCSelectorItemViewProps {
    machine: MachineViewModel;
    addAnotherPc: boolean;
    onClickHandler: () => void;
}

export class PCSelectorItemView extends React.Component<IPCSelectorItemViewProps, IPCSelectorItemViewState> {
    constructor(props) {
        super(props);

        this.state = { imageLoadError: false };
    }

    public render() {
        return this.GetPCSelectorContent();
    }

    private GetPCSelectorContent(): JSX.Element {
        if (this.props.addAnotherPc) {
            return <div className="pcSelectorItem" onClick={() => this.props.onClickHandler()}>
                <div className="imageContainer" id="imageContainer">
                    <img className="image" src={addAnotherPc} />
                </div>
                <label className="pcName" id="pcName">
                    Add Another PC
                </label>
            </div>
        }

        if (this.props.machine.machineImageUrl != null && !this.state.imageLoadError) {
            return <div className="pcSelectorItem" onClick={() => this.props.onClickHandler()}>
                <div className="imageContainer" id="imageContainer">
                    <img id="image" className="image" src={this.props.machine.machineImageUrl}
                        onError={() => { this.setState({ imageLoadError: true }); }}
                        onLoadedData={() => { this.setState({ imageLoadError: false }); }} />
                </div>
                <label className="pcName" id="pcName">
                    {this.props.machine.machineName.substring(0, 20)}
                </label>
            </div>
        }

        return <div className="pcSelectorItem" onClick={() => this.props.onClickHandler()}>
            <div className="imageContainer" id="imageContainer">
                <img className="image" id="defaultPCImage" src={defaultPC} />
            </div>
            <label className="pcName" id="pcName">
                {this.props.machine.machineName.substring(0, 20)}
            </label>
        </div>
    }
}


