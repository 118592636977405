import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { AddAnotherPcView } from "../main/components/AddAnotherPcView";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { ContactPane } from "./ContactPane";
import { OptimizationPane } from "./OptimizationPane";
import { PageBoostPane } from "./PageBoostPane";
import { ServicePane } from "./ServicePane";
import { SummaryPane } from "./SummaryPane";
import { BrowserDetection } from "../core/classes/BrowserDetection";


export interface IOverviewViewState {
    showAddAnotherPcView: boolean;
}

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class OverviewView extends React.Component<Partial<IComponentProps>, IOverviewViewState> {
    constructor(props) {
        super(props);

        this.state = {
            showAddAnotherPcView: false
        };

        this.onAddAnotherPC = this.onAddAnotherPC.bind(this);
        this.onDeactivatePC = this.onDeactivatePC.bind(this);
        this.onSelectAnotherPC = this.onSelectAnotherPC.bind(this);
        this.onCloseAddAnotherPCView = this.onCloseAddAnotherPCView.bind(this);
        this.onPageBoostClicked = this.onPageBoostClicked.bind(this);
        this.DownloadPortalBuildClicked = this.DownloadPortalBuildClicked.bind(this);
    }

    public render() {

        const isChrome = BrowserDetection.isChrome();
        const activeMachine = this.props.viewStore.allMachines[this.props.viewStore.currentMachineIndex];


        try {
            return <div id="overview" className="overview">

                {isChrome &&
                    <PageBoostPane onPageBoostClicked={this.onPageBoostClicked} />
                }
                {activeMachine &&
                    <SummaryPane vm={activeMachine}
                        onAddAnotherPC={this.onAddAnotherPC}
                        onDeactivatePC={this.onDeactivatePC}
                        onSelectAnotherPC={this.onSelectAnotherPC}
                        isLastMachine={this.props.viewStore.enabledMachines.length == 1} />
                }
                {activeMachine &&
                    <ServicePane vm={activeMachine.driverSummary} />
                }
                {activeMachine &&
                    <OptimizationPane vm={activeMachine.softwareOptimizationSummary} />
                }

                {this.state.showAddAnotherPcView &&
                    <AddAnotherPcView onCloseClickedCallback={this.onCloseAddAnotherPCView} onDownloadClickedCB={this.DownloadPortalBuildClicked} />
                }
            </div>
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering OverviewView - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private onSelectAnotherPC() {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.PCSelector);
        this.props.dispatcher.DispatchAction(action);
    }

    private onDeactivatePC() {
        const action = AppActionHelpers.CreateAction(AppActions.DEACTIVATE_CURRENT_MACHINE, null);
        this.props.dispatcher.DispatchAction(action);
    }

    private onAddAnotherPC() {
        this.setState({
            showAddAnotherPcView: true
        });
    }

    private onCloseAddAnotherPCView() {
        this.setState({
            showAddAnotherPcView: false
        });
    }

    private onPageBoostClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.LAUNCH_PAGE_BOOST, null);
        this.props.dispatcher.DispatchAction(action);
    }

    private DownloadPortalBuildClicked() {
        this.props.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.DOWNLOAD_DSONE, null));
    }
}