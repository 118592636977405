import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { UserDataUpdate } from "../models/UserDataUpdate";
import { EmailValidator } from "commons-validator-js";

interface IUpdateProps {
    canUpdate: boolean;
}

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class UpdatePersonalInformationView extends React.Component<Partial<IComponentProps>, Partial<IUpdateProps>>{
    private emailInput;
    private get $emailInput() {
        return $(this.emailInput);
    }

    private firstNameInput;
    private get $firstNameInput() {
        return $(this.firstNameInput);
    }

    private lastNameInput;
    private get $lastNameInput() {
        return $(this.lastNameInput);
    }

    private passwordInput;
    private get $passwordInput() {
        return $(this.passwordInput);
    }

    private confirmPasswordInput;
    private get $confirmPasswordInput() {
        return $(this.confirmPasswordInput);
    }

    private $inputFields: Array<JQuery>;

    constructor(props) {
        super(props);

        this.state = { canUpdate: false };

        this.onBillingInfoClicked = this.onBillingInfoClicked.bind(this);
        this.onCancelClicked = this.onCancelClicked.bind(this);
        this.onUpdateClicked = this.onUpdateClicked.bind(this);
    }

    public componentDidMount() {
        this.Initialize();
    }

    public render() {
        try {
            const error = this.props.viewStore.updatePersonalInfoError;
            const locked = this.props.viewStore.isUILocked;
            const user = this.props.viewStore.user;
            const hasRecurringSubscription = user.HasRecurringSubscription;

            let opts: Object = {};

            if (locked) {
                opts['disabled'] = 'disabled';
            }

            return <form className="updatePersonalInfo appPanel defaultMaterial" autoComplete="false">
                <h2 className="panelTitle">Update Your Information</h2>
                <p className="piLabel">First Name:</p>
                <input {...opts} name="FirstName" className="piInput nameInput" placeholder={user.FirstName} type="text" ref={(ref) => { this.firstNameInput = ref; }} />
                <p className="piLabel">Last Name:</p>
                <input {...opts} name="LastName" className="piInput nameInput" placeholder={user.LastName} type="text" ref={(ref) => { this.lastNameInput = ref; }} />
                <p className="piLabel">Email:</p>
                <input {...opts} name="Email" className="piInput emailInput" placeholder={user.Email} type="text" ref={(ref) => { this.emailInput = ref; }} />
                <p className="piLabel">Password:</p>
                <input {...opts} autoComplete="new-password" name="Password" className="piInput passwordInput" placeholder='●●●●●●●●' type="password" ref={(ref) => { this.passwordInput = ref; }} />
                <p className="piLabel">Confirm Password:</p>
                <input {...opts} name="ConfirmPassword" className="piInput confirmPasswordInput" placeholder='●●●●●●●●' type="password" ref={(ref) => { this.confirmPasswordInput = ref; }} />
                {hasRecurringSubscription &&
                    <button type="button" onClick={this.onBillingInfoClicked} className="updateBillingLink linkButton">Update billing information</button>
                }
                {locked &&
                    <div className="updatingUserInfoBox">
                        <div className="loaderImage" />
                        <p className="updatingUserInfoText">Updating your information...</p>
                    </div>
                }
                {error &&
                    <div className="updateErrorBox">
                        <div className="errorImage" />
                        <p className="updateErrorText">{error.Message}</p>
                    </div>
                }
                <button disabled={!this.state.canUpdate} type="submit" className="piUpdateButton appButton blue" onSubmit={this.onUpdateClicked}>
                    <p className="appButton__buttonText">Update</p>
                </button>
                <button type="button" className="piCancelButton appButton grey" onClick={this.onCancelClicked}>
                    <p className="appButton__buttonText">Cancel</p>
                </button>
            </form>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering UpdatePersonalInformationView - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private Initialize() {

        const user = this.props.viewStore.user;

        this.$inputFields = new Array(this.$emailInput, this.$firstNameInput, this.$lastNameInput, this.$passwordInput, this.$confirmPasswordInput);
        this.$inputFields.forEach((field) => {
            $(field).addClass('default');
            $(field).focusin((e) => { field.attr('placeholder', ''); });
        });

        this.$emailInput.focusout((e) => {
            this.$emailInput.attr('placeholder', user.Email);
        })

        this.$firstNameInput.focusout((e) => {
            this.$firstNameInput.attr('placeholder', user.FirstName);
        })

        this.$lastNameInput.focusout((e) => {
            this.$lastNameInput.attr('placeholder', user.LastName);
        })

        this.$passwordInput.focusout((e) => {
            this.$passwordInput.attr('placeholder', '●●●●●●●●');
        })

        this.$confirmPasswordInput.focusout((e) => {
            this.$confirmPasswordInput.attr('placeholder', '●●●●●●●●');
        })

        $('.updatePersonalInfo input[type="text"], .updatePersonalInfo input[type="password"]').tooltipster({
            trigger: 'custom', // default is 'hover' which is no good here
            onlyOne: false, // allow multiple tips to be open at a time
            position: 'right', // display the tips to the right of the element
            theme: 'error-tooltip'
        });
        this.ValidationInit();
        this.bindEvents();

        // set initial state of update button        
        this.setState({ canUpdate: !this.$inputFields.every(f => f.val() === "") });
    }

    private ValidationInit() {
        const validator = new EmailValidator();
        $.validator.addMethod("commonEmailValidation", function (value, element) {
            return validator.isValid(value);
        });
        $('.updatePersonalInfo').validate({
            errorPlacement: function (error, element) {
                var lastError = $(element).data('lastError'),
                    newError = $(error).text();

                $(element).data('lastError', newError);

                if (newError !== '' && newError !== lastError) {
                    $(element).tooltipster('content', newError);
                    $(element).tooltipster('open');
                }
            },
            rules: {
                Email: { email: true, commonEmailValidation: true },
                Password: { minlength: 4 },
                ConfirmPassword: { equalTo: '.passwordInput' }

            },
            submitHandler: () => {
                this.updateUserInfo();
            },
            success: function (label, element) {
                $(element).tooltipster('close');
            },
            messages: {
                Email: { email: "Please enter a valid email", commonEmailValidation: "Please enter a valid email" },
                Password: { minlength: "Passwords must be at least 4 characters" },
                ConfirmPassword: { equalTo: "Passwords must match" }
            }

        });
    }

    private bindEvents() {
        this.$inputFields.forEach((field) => {
            field.on('input', () => {
                field.removeClass('default');
                // set state for 'Update' button
                this.setState({ canUpdate: !this.$inputFields.every(f => f.val() === "") });
            });

            field.focusout(() => {
                if (field.val() === "")
                    field.addClass('default');
            });

        });
    }

    private onBillingInfoClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.UpdatePayment);
        this.props.dispatcher.DispatchAction(action);
    }

    private onUpdateClicked() {
        if (this.props.viewStore.isUILocked) {
            return;
        } else {
            $('updatePersonalInfo').submit();
        }
    }

    private onCancelClicked() {
        if (this.props.viewStore.isUILocked) {
            return;
        } else {
            const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.Overview);
            this.props.dispatcher.DispatchAction(action);
        }
    }

    private updateUserInfo() {
        var payload = this.GetUserInfoForSubmit();
        //don't bother sending an empty payload
        if (!payload.IsValid) return;

        const action = AppActionHelpers.CreateAction(AppActions.UPDATE_USER_INFO, payload);
        this.props.dispatcher.DispatchAction(action);
    }

    private GetUserInfoForSubmit(): UserDataUpdate {
        let userInfo = new UserDataUpdate();
        if (this.$firstNameInput.val()) {
            userInfo.firstName = this.$firstNameInput.val().toString();
        }
        if (this.$lastNameInput.val()) {
            userInfo.lastName = this.$lastNameInput.val().toString();
        }
        if (this.$emailInput.val()) {
            userInfo.email = this.$emailInput.val().toString();
        }
        if (this.$passwordInput.val()) {
            userInfo.password = this.$passwordInput.val().toString();
        }
        return userInfo;
    }
}