import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { BillingHistoryView } from "../payment/BillingHistoryView";
import { PaymentDeclinedView } from "../payment/PaymentDeclinedView";
import { SelectPaymentView } from "../payment/SelectPaymentView";
import { UpdatePaymentView } from "../payment/UpdatePaymentView";
import { SubscriptionDetailView } from "./SubscriptionDetailView";
import { CancelSubscription } from "../cancelsubscription/CancelSubscription";
import { CancelSubscription2 } from "../cancelsubscription/CancelSubscription2";
import { CancelSubscription3 } from "../cancelsubscription/CancelSubscription3";
import { CancelSubscription4 } from "../cancelsubscription/CancelSubscription4";
import { CancelSubscription5 } from "../cancelsubscription/CancelSubscription5";
import { UpdatePersonalInformationView } from "./UpdatePersonalInformationView";
import { PersonalInformationView } from "./PersonalInformationView";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class SubscriptionsAndBillingView extends React.Component<Partial<IComponentProps>> {

    constructor(props) {
        super(props);
    }

    public render() {
        try {
            return <div className="subscriptionsAndBilling">
                {this.GetUIDisplay()}
            </div>;

        }
        catch (ex) {
            let outerex = new Error(`Error Rendering SubscriptionsAndBillingView - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private GetUIDisplay(): JSX.Element {

        const activeScreen = this.props.viewStore.activeScreen;

        switch (activeScreen) {
            case AppViews.SubscriptionDetail:
                return <div className="subscriptionsAndBillingWrapper wrapper">
                    <SubscriptionDetailView />
                    <PersonalInformationView />
                </div>;
            case AppViews.UpdatePayment:
                return <div className="subscriptionsAndBillingWrapper wrapper">
                    <UpdatePaymentView />
                </div>;
            case AppViews.UpdatePaymentAndResume:
                return <div className="subscriptionsAndBillingWrapper wrapper">
                    <UpdatePaymentView />
                </div>;
            case AppViews.SelectPayment:
                return <div className="subscriptionsAndBillingWrapper wrapper">
                    <SelectPaymentView />
                </div>;
            case AppViews.ViewInvoices:
                return <div className="subscriptionsAndBillingWrapper wrapper">
                    <BillingHistoryView />
                </div>;
            case AppViews.PaymentDeclined:
                return <div className="subscriptionsAndBillingWrapper wrapper">
                    <PaymentDeclinedView />
                </div>;
            case AppViews.CancelSubscription:
                return <div className="subscriptionsAndBillingWrapper wrapper">
                    <CancelSubscription />
                </div>;
            case AppViews.CancelSubscription2:
                return <div className="subscriptionsAndBillingWrapper wrapper">
                    <CancelSubscription2 />
                </div>;
            case AppViews.CancelSubscription3:
                return <div className="subscriptionsAndBillingWrapper wrapper">
                    <CancelSubscription3 />
                </div>;
            case AppViews.CancelSubscription4:
                return <div className="subscriptionsAndBillingWrapper wrapper">
                    <CancelSubscription4 />
                </div>;
            case AppViews.CancelSubscription5:
                return <div className="subscriptionsAndBillingWrapper wrapper">
                    <CancelSubscription5 />
                </div>;
        }
    }
}
