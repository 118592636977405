import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { ContactModel } from "../models/ContactModel";

export interface IContactFormProps extends IComponentProps {
    topic: string;
}

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class ContactForm extends React.Component<Partial<IContactFormProps>> {
    private emailInput: JQuery;
    private nameInput: JQuery;
    private messageInput: JQuery;

    constructor(props) {
        super(props);

        this.onBackClicked = this.onBackClicked.bind(this);
        this.sendContactMessage = this.sendContactMessage.bind(this);
    }

    public render() {

        const isLocked = this.props.viewStore.isUILocked;
        const isLoggedIn = this.props.viewStore.isLoggedIn;

        let opts: Object = {};
        if (isLocked) {
            opts['disabled'] = 'disabled';
        }
        const topic = this.props.topic;
        const error = this.props.viewStore.contactError;

        return <form className="contactForm" autoComplete="false">
            <h2 className="panelTitle">{topic}</h2>
            <p className="contactSubHeader">Use the form below to contact support</p>
            <div className="contactFieldsWrapper">
                {(!isLoggedIn) &&
                    <div className="loggedOutFields">
                        <p className="contactFieldLabel">Name:</p>
                        <input {...opts} className="contactField" type="text" name="Name" ref={(input) => { this.nameInput = $(input) }} />
                        <p className="contactFieldLabel">Email:</p>
                        <input {...opts} className="contactField" type="text" name="Email" ref={(input) => { this.emailInput = $(input) }} />
                    </div>
                }
                <p className="contactFieldLabel">Message:</p>
                <textarea {...opts} className="messageField"  name="Message" ref={(input) => { this.messageInput = $(input) }} />
            </div>
            {isLocked &&
                <div className="miniLoader">
                    <div className="loaderImage" />
                    <p className="miniLoaderText">Sending message...</p>
                </div>
            }
            {error &&
                <div className="miniErrorWrapper">
                    <div className="errorImage" />
                    <p className="miniErrorText">Error sending message</p>
                </div>
            }

            <button type="submit" className="contactSubmitButton appButton blue">
                <p className="appButton__buttonText">Send</p>
            </button>
            <button type="button" className="backButton appButton grey" onClick={this.onBackClicked}>
                <p className="appButton__buttonText">Go Back</p>
            </button>
        </form>;
    }

    public componentDidMount() {
        this.Initialize();
    }

    private Initialize() {
        $('.contactForm input[type="text"]').tooltipster({
            trigger: 'custom', // default is 'hover' which is no good here
            onlyOne: false, // allow multiple tips to be open at a time
            position: 'right', // display the tips to the right of the element
            theme: 'error-tooltip'
        });
        this.ValidationInit();
    }

    private ValidationInit() {
        $('.contactForm').validate({
            errorPlacement: function (error, element) {
                var lastError = $(element).data('lastError'),
                    newError = $(error).text();

                $(element).data('lastError', newError);

                if (newError !== '' && newError !== lastError) {
                    $(element).tooltipster('content', newError);
                    $(element).tooltipster('open');
                }
            },
            rules: {
                Email: { required: true, email: true },
                Name: { required: true, minlength: 2 },
            },
            submitHandler: () => {
                if (this.props.viewStore.isUILocked) {
                    return;
                }
                this.sendContactMessage();
            },
            success: function (label, element) {
                $(element).tooltipster('close');
            },
            messages: {
                Email: "Please enter a valid email",
                Name: "Please enter a valid name"
            }

        });
    }

    private sendContactMessage() {

        const payload = new ContactModel();

        if (this.props.viewStore.isLoggedIn && this.props.viewStore.user) {
            payload.Email = this.props.viewStore.user.Email;
            payload.Name = this.props.viewStore.userFullName;
        } else {
            payload.Email = this.emailInput.val().toString();
            payload.Name = this.nameInput.val().toString();
        }

        payload.Message = this.messageInput.val().toString();
        payload.SupportTopic = this.props.topic;

        const action = AppActionHelpers.CreateAction(AppActions.CONTACT, payload);
        this.props.dispatcher.DispatchAction(action);
    }

    private onBackClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.CONTACT_FORM_BACK, AppViews.Support);
        this.props.dispatcher.DispatchAction(action);
    }
}