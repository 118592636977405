import * as React from "react";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { ISubscriptionViewModel } from "../models/ISubscriptionViewModel";
import { AppViews } from "../core/enumerations/AppViews";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { IViewStore } from "../store/interfaces/IViewStore";
import { IExceptionReporter } from "../api/interfaces/IExceptionReporter";
import { IDispatcher } from "driversupport.frontend.common";
// @ts-ignore
import operator from "../../assets/img/operator.png";

declare var imagePath;
declare var cdnContentVersion;
declare var supportPhoneNumber;
declare var supportOfficeHours;

export interface ISupportHomeProps {
    viewStore: IViewStore,
    dispatcher: IDispatcher<AppActions>,
    exceptionReporter: IExceptionReporter
}

export class SupportHome extends React.Component<Partial<ISupportHomeProps>, undefined>
{
    constructor(props) {
        super(props);

        this.onTopicClicked = this.onTopicClicked.bind(this);
    }

    public render() {
        try {
            return this.GetDisplay();
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering Support Screen - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private GetDisplay() {
        const isLoggedIn = this.props.viewStore.isLoggedIn;
        let hasRecurringSubscription;
        let subscriptions: ISubscriptionViewModel[];
        let subscriptionCount: number;
        let hasActiveSubscription: boolean;

        if (this.props.viewStore.userPortalViewModel && this.props.viewStore.userPortalViewModel.AccountViewModel) {
            subscriptions = this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions;
            subscriptionCount = subscriptions.length;
            hasActiveSubscription = !(subscriptions.find((sub: ISubscriptionViewModel) => sub.IsActive) == undefined);
            hasRecurringSubscription = this.props.viewStore.user.HasRecurringSubscription;
        }

        return <div className="wrapper supportHome">
            <div className="support appPanel defaultMaterial">
                <div className="supportHomeHeader" >
                    <img src={operator} />
                    <div className="supportHomeHeaderText" >
                        <h2 className="panelTitle">Signature Support Options</h2>
                        <p className="supportSubHeader">We're here to help you with your PC support needs. Please let us know how we can help today.</p>
                    </div>
                </div>
                <div className="supportCategoriesSection">
                    <p>We have a robust online Knowledge Base help section available.</p>
                    <div className="supportCategoriesPanel">
                        <div className="supportCategoriesWrapper">
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/bluetooth/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/bluetooth.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">Bluetooth</div>
                                </div>
                            </a>
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/browsers/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/browser.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">Browsers</div>
                                </div>
                            </a>
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/dvd/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/dvd.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">DVD</div>
                                </div>
                            </a>
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/hardware/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/hardware.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">Hardware</div>
                                </div>
                            </a>
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/laptops/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/laptop.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">Laptops</div>
                                </div>
                            </a>
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/monitors/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/monitor.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">Monitors</div>
                                </div>
                            </a>
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/mouses/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/mouse.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">Mouses</div>
                                </div>
                            </a>
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/network/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/network.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">Network</div>
                                </div>
                            </a>
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/pc-gaming/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/pc-gaming.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">PC Gaming</div>
                                </div>
                            </a>
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/pc-maintenance/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/pc-maintenance.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">PC Maintenance</div>
                                </div>
                            </a>
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/phones/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/smartphone.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">Phones</div>
                                </div>
                            </a>
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/printers/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/printer.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">Printers</div>
                                </div>
                            </a>
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/scanners/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/scanner.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">Scanners</div>
                                </div>
                            </a>
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/security/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/security.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">Security</div>
                                </div>
                            </a>
                            <a href="https://www.driversupport.com/blog/knowledge-article/category/software/">
                                <div className="supportCategory">
                                    <img src={`${imagePath}support/icons/software.svg?v=${cdnContentVersion}`} />
                                    <div className="supportCategoryText">Software</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="topicsWrapper">
                    <div className="supportTopic driverInstall appPanel defaultMaterial" onClick={() => { this.onTopicClicked(AppViews.InstallDriverProblem); }}>
                        <p className="supportTopicText">How do I install my driver?</p>
                    </div>
                    <div className="supportTopic softwareInstall appPanel defaultMaterial" onClick={() => { this.onTopicClicked(AppViews.SoftwareInstallProblem); }}>
                        <p className="supportTopicText">I have a software install problem</p>
                    </div>
                    <div className="supportTopic driverUpdate appPanel defaultMaterial" onClick={() => this.onTopicClicked(AppViews.DriverQuestionForm)}>
                        <p className="supportTopicText">My driver will not update</p>
                    </div>
                    <div className="supportTopic deviceSpecific appPanel defaultMaterial" onClick={() => this.onTopicClicked(AppViews.DriverQuestionForm)}>
                        <p className="supportTopicText">Device specific problem</p>
                    </div>
                    {isLoggedIn && this.props.viewStore.userPortalViewModel && hasRecurringSubscription && !hasActiveSubscription &&
                        <div className="supportTopic cancelSubscriptionTile appPanel defaultMaterial" onClick={() => { this.onTopicClicked(AppViews.SubscriptionDetail); }}>
                            <p className="supportTopicText">How do I renew my subscription</p>
                        </div>
                    }
                    {isLoggedIn && !hasRecurringSubscription &&
                        <div className="supportTopic refundRequest appPanel defaultMaterial" onClick={() => { this.onTopicClicked(AppViews.RequestARefundForm); }}>
                            <p className="supportTopicText">Request a refund</p>
                        </div>
                    }
                    <div className="clear"></div>
                </div>
            </div>
        </div>;
    }

    private onTopicClicked(screen: AppViews) {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, screen);
        this.props.dispatcher.DispatchAction(action);
    }
}