import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IErrorViewModel } from "../core/interfacaes/IErrorViewModel";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { LoginPayload } from "../models/LoginPayload";

declare var authControllerPath: string;

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class LoginView extends React.Component<Partial<IComponentProps>, undefined>
{
    private emailInput: HTMLInputElement;
    private passwordInput: HTMLInputElement;

    private get email(): string {
        return $('.emailInput').val().toString();
    }

    private get password(): string {
        return $('.passwordInput').val().toString();
    }

    private get rememberMe(): boolean {
        return $('.rememberMe').is(':checked');
    }

    constructor(props) {
        super(props);
        
        this.onForgotPasswordClicked = this.onForgotPasswordClicked.bind(this);
    }

    public render() {

        const isLoggingIn: boolean = this.props.viewStore.isLoggingIn;
        const error: IErrorViewModel = this.props.viewStore.loginError;

        try {
            return <div className="wrapper">
                <form className=" appPanel loginForm defaultMaterial" autoComplete="false">
                    <h2 className="loginFormHeader">Login To Your Account</h2>
                    <p className="loginFormSubHeader">Please enter your email and password</p>
                    <label className="emailLabel loginFormLabel">Email</label>
                    <input type="text" autoComplete="false" className="emailInput loginFormInput" name="Email" ref={(ref) => { this.emailInput = ref; }} onBlur={() => this.onEmailChanged()} />

                    <label className="passwordLabel loginFormLabel">Password</label>
                    <input type="password" autoComplete="false" className="passwordInput loginFormInput" name="Password" ref={(ref) => { this.passwordInput = ref; }} />

                    <div className="rememberWrapper">
                        <input type="checkbox" className="rememberMe checkBox" />
                        <label className="rememberMeLabel">Remember Me</label>
                    </div>
                    <button type="button" className="forgotPasswordText linkButton" onClick={this.onForgotPasswordClicked}>Forgot your password?</button>
                    <div className="clear"></div>
                    {isLoggingIn &&
                        <div className="loggingInBox">
                            <div className="loaderImage" />
                            <p className="loggingInText">Logging in...</p>
                        </div>
                    }
                    {error &&
                        <div className="loginErrorWrapper miniErrorWrapper">
                            <div className="errorImage" />
                            <p className="loginErrorText miniErrorText">{error.Message}</p>
                        </div>
                    }
                    <button type="submit" className="loginButton appButton blue">
                        <p className="appButton__buttonText">Log In</p>
                    </button>
                </form>
            </div>;
        } catch (ex) {
            let outerex = new Error(`Error Rendering Login Form - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    public componentDidMount() {
        this.Initialize();
    }

    private Initialize() {
        $('.loginForm input[type="text"], .loginForm input[type="password"]').tooltipster({
            trigger: 'custom', // default is 'hover' which is no good here
            onlyOne: false, // allow multiple tips to be open at a time
            position: 'right', // display the tips to the right of the element
            theme: 'error-tooltip'
        });
        this.ValidationInit();
        const params = new URLSearchParams(window.location.search);
        const email = params.get("email");
        $(this.emailInput).val(email);
        $(this.passwordInput).val("");
        $(this.emailInput).focus();
    }

    private ValidationInit() {
        $('.loginForm').validate({
            errorPlacement: function (error, element) {
                var lastError = $(element).data('lastError'),
                    newError = $(error).text();

                $(element).data('lastError', newError);

                if (newError !== '' && newError !== lastError) {
                    $(element).tooltipster('content', newError);
                    $(element).tooltipster('open');
                }
            },
            rules: {
                Email: { required: true, email: true },
                Password: "required"
            },
            submitHandler: () => {
                if (this.props.viewStore.isLoggingIn) {
                    return;
                }
                this.Login(this.email, this.password, this.rememberMe);
            },
            success: function (label, element) {
                $(element).tooltipster('close');
            },
            messages: {
                Email: "Please enter a valid email",
                Password: "Please enter your password"
            }

        });
    }

    private Login(username: string, password: string, rememberMe: boolean) {

        const payload = new LoginPayload();
        payload.password = password;
        payload.rememberMe = rememberMe;
        payload.username = username;

        const action = AppActionHelpers.CreateAction(AppActions.LOGIN, payload);
        this.props.dispatcher.DispatchAction(action);
    }

    private onForgotPasswordClicked() {
        location.href = `${authControllerPath}forgotpassword/`;
    }

    private async onEmailChanged() {
        const email = this.emailInput.value;
        const url = `${window.location.origin}/ui/haswebaccount?email=${email}`;
        const result = await fetch(url);
        const hasWebAccount = await result.json();
        if (hasWebAccount) {
            const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.ProductRedirect);
            this.props.dispatcher.DispatchAction(action);
        }
    }
}