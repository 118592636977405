import { ICallbackPayload, IDispatcher } from "driversupport.frontend.common";
import { inject, injectable, postConstruct } from "inversify";
import { action, runInAction } from "mobx";
import { IAuthApi } from "../../api/interfaces/IAuthApi";
import { IExceptionReporter } from "../../api/interfaces/IExceptionReporter";
import { IUIApi } from "../../api/interfaces/IUIApi";
import { ErrorViewModel } from "../../core/classes/ErrorViewModel";
import { AppActions } from "../../core/enumerations/AppActions";
import { AppViews, ForgotPasswordViews } from "../../core/enumerations/AppViews";
import { TYPES } from "../../core/enumerations/TYPES";
import { IViewStore } from "../../store/interfaces/IViewStore";
import { IForgotPasswordObserver } from "../interfaces/IForgotPasswordObserver";



@injectable()
export class ForgotPasswordObserver implements IForgotPasswordObserver {

    private readonly viewStore: IViewStore;
    private readonly authApi: IAuthApi;
    private readonly uiApi: IUIApi;
    private readonly exceptionReporter: IExceptionReporter;
    private readonly dispatcher: IDispatcher<AppViews>;

    constructor(@inject(TYPES.ViewStore) viewStore: IViewStore,
        @inject(TYPES.ExceptionReporter) exceptionReporter: IExceptionReporter,
        @inject(TYPES.AuthApi) authApi: IAuthApi,
        @inject(TYPES.UIApi) uiApi: IUIApi,
        @inject(TYPES.Dispatcher) dispatcher: IDispatcher<AppViews>) {

        this.viewStore = viewStore;
        this.authApi = authApi;
        this.uiApi = uiApi;
        this.exceptionReporter = exceptionReporter;
        this.dispatcher = dispatcher;

        this.dispatcher.Register(this.dispatcherCallback.bind(this));
    }

    // this is called when inversify creates an instance of this class
    @postConstruct()
    @action
    public async init() {
        try {
            this.viewStore.activeScreen = AppViews.ForgotPassword;
            this.viewStore.forgotPasswordView = ForgotPasswordViews.InputEmail;
            this.viewStore.isLoggedIn = false;
        }
        catch (e) {
            this.exceptionReporter.ReportException(e);
            runInAction(() => {
                this.viewStore.isError = true;
            });
        }
    }

    private dispatcherCallback(payload: ICallbackPayload<AppActions>): void {
        try {
            switch (payload.action.actionType) {

                case AppActions.FORGOT_PASSWORD:
                    this.handleForgotPassword(payload.action.data as string);
                    break;
                case AppActions.SELECT_FORGOT_PASSWORD_SCREEN:
                    this.handleSelectForgotPasswordScreen(payload.action.data as ForgotPasswordViews);
                    break;
                case AppActions.RESEND_FORGOT_PASSWORD:
                    this.handleResendForgotPassword(payload.action.data as string);
                    break;
            }
        } catch (ex) {
            const error = new Error("Error processing app action in DispatcherCallback() in ForgotPasswordObserver");
            this.exceptionReporter.ReportCriticalException(error);
        }
    }


    @action
    private async handleResendForgotPassword(resendEmail:string) {
        this.viewStore.forgotPasswordView = ForgotPasswordViews.InputEmail;
        await this.handleForgotPassword(resendEmail);
    }


    @action
    private handleSelectForgotPasswordScreen(view: ForgotPasswordViews) {
        this.viewStore.forgotPasswordError = null;
        this.viewStore.forgotPasswordView = view;
    }


    @action
    private async handleForgotPassword(resendEmail: string) {
        try {
            this.viewStore.isUILocked = true;
            this.viewStore.forgotPasswordError = null;

            const response = await this.authApi.sendPasswordResetEmail(resendEmail);

            if (response.isSuccessfulAction) {
                runInAction(() => {
                    this.viewStore.sentPasswordEmail = resendEmail;
                    this.viewStore.forgotPasswordEmail = null;
                    this.viewStore.forgotPasswordView = ForgotPasswordViews.EmailSent;
                });
            } else {
                throw new Error(response.errorMessage);
            }
        }
        catch (e) {
            this.exceptionReporter.ReportException(e);
            runInAction(() => {
                if (e.message.toLowerCase().includes("user not found")) {
                    this.viewStore.forgotPasswordError = new ErrorViewModel("No account found for this email address.");
                } else {
                    this.viewStore.forgotPasswordError = new ErrorViewModel("Error sending email.");
                }
            });
        }
        finally {
            runInAction(() => {
                this.viewStore.isUILocked = false;
            });
        }
    }
}
