import { inject, observer } from "mobx-react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import * as React from "react";
import { ISubscriptionViewModel } from "../models/ISubscriptionViewModel";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { GoogleAnalytics } from "../core/classes/Analytics";
import { AppViews } from "../core/enumerations/AppViews";
import { CancelProgressBar } from "./CancelProgressBar";
import { ContactPane } from "../overview/ContactPane";
import { ServicePane } from "../overview/ServicePane";
import { MachineViewModel } from "../viewModels/MachineViewModel";

declare var _kmq;

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class CancelSubscription2 extends React.Component<Partial<IComponentProps>, undefined>{
    private activeMachine: MachineViewModel;

    constructor(props) {
        super(props);
        this.onEndBenefitsClicked = this.onEndBenefitsClicked.bind(this);
        this.onKeepBenefitsClicked = this.onKeepBenefitsClicked.bind(this);
        this.activeMachine = this.props.viewStore.allMachines[this.props.viewStore.currentMachineIndex];

        _kmq.push(['record', "Cancel Step 2"]);
    }

    public render() {
        try {
            return <div className="cancelSubscriptionWrapper" id="cancelSubscriptionWrapper">
                <CancelProgressBar step={2} />
                <ServicePane vm={this.activeMachine.driverSummary} />
                <div className="cancelContinueChallenge appPanel defaultMaterial" id="cancelContinueChallenge">
                    <h2 className="cancelContinueChallengeHeader" id="cancelContinueChallengeHeader">Are you sure you want to cancel and lose these benefits?</h2>
                    <div className="cancelButtonArea" id="cancelButtonArea">
                        <button type="button" className="appButton grey endBenefitsButton cancelStepButton" id="endBenefitsButton" onClick={this.onEndBenefitsClicked}>End My Benefits</button>
                        <div className="cancelButtonDivider" id="cancelButtonDivider"></div>
                        <button type="button" className="appButton green keepBenefitsButton cancelStepButton" id="keepBenefitsButton" onClick={this.onKeepBenefitsClicked}>Keep My Benefits</button>
                    </div>
                </div>
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering cancel Subscription ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportCriticalException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    public componentDidMount() {
        //prevent invalid subscription navigation and prevent navigation to this page when subscription is already cancelled
        let selectedSubscription = this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions.find((sub: ISubscriptionViewModel) => sub.SubscriptionId == this.props.viewStore.activeSubscriptionId);
        if (!selectedSubscription || selectedSubscription.IsActive == false) {
            const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.Overview);
            this.props.dispatcher.DispatchAction(action);
        }
        if (!this.activeMachine) {
            this.onEndBenefitsClicked();
        }
    }

    private onEndBenefitsClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.CANCEL_SUBSCRIPTION_ADVANCE, AppViews.Overview);
        this.props.dispatcher.DispatchAction(action);
    }

    private onKeepBenefitsClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.CANCEL_SUBSCRIPTION_BACK, null);
        this.props.dispatcher.DispatchAction(action);
    }
}
