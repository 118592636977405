import "babel-polyfill"; // neded for ie11 compatibility
import "isomorphic-fetch"; // needed for iell compatibility
import { configure } from "mobx";
import { Provider } from "mobx-react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import "reflect-metadata";
import { AppContext } from "./core/classes/AppContext";
import { TypeContainerFactory } from "./core/classes/TypeContainerFactory";
import { ErrorBoundary } from "./core/components/ErrorBoundary";
import { UI } from "./main/components/UI";
import * as Sentry from "@sentry/browser";
import AppLoadWrapper from "./main/components/AppLoadWrapper";
import "../src/style/main.css";

// jquery plugins
require("jquery.payment"); 
require("tooltipster");
require("jquery-validation");

//const localeData: any = require("./../locale/data.json");

//addLocaleData([...deLocale, ...enLocale]);
//const language = "en";
//const languageWithoutRegionCode: string = language.toLowerCase().split(/[_-]+/)[0];

// try full locale, fallback to locale without region code, fallback to en
//const messages: any = localeData[languageWithoutRegionCode] || localeData[language] || localeData.en;


Sentry.init({ dsn: 'https://24b1fd9e11fa42c6baf57095d0df4b39@sentry.io/1451249' });
configure({ enforceActions: "always" })
const container = TypeContainerFactory.GetContainer();
const appContext: AppContext = new AppContext(container);

//appContext.Store.ViewStore.locale = language;

ReactDOM.render(
    <ErrorBoundary ExceptionReporter={appContext.ExceptionReporter}>
        <Provider appContext={appContext}>
            <IntlProvider locale={"en"} messages={null}>
                <AppLoadWrapper />
            </IntlProvider>
        </Provider>
    </ErrorBoundary>,
    document.getElementById("uiMain"),
);