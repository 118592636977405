import { inject, observer } from "mobx-react";
import * as React from "react";
// @ts-ignore
import newPcIcon from "../../../assets/img/newPc.png";
// @ts-ignore
import operator from "../../../assets/img/operator.png";
// @ts-ignore
import optionOne from "../../../assets/img/optionOne.png";
// @ts-ignore
import optionTwo from "../../../assets/img/optionTwo.png";
// @ts-ignore
import xIcon from "../../../assets/img/xIcon_dark.png";
import { IProviderWrapper } from "../../core/classes/AppContext";
import { IComponentProps } from "../interfaces/IComponentProps";

declare var portalBuildUrl: string;
declare var authControllerPath: string;
declare var supportPhoneNumber: string;

export interface IAddAnotherPcViewProps extends IComponentProps {
    onCloseClickedCallback: () => void;
    onDownloadClickedCB: () => void;
}

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter,
}))
@observer
export class AddAnotherPcView extends React.Component<Partial<IAddAnotherPcViewProps>, undefined>
{
    public render() {
        try {

            return <div className="addAnotherPcViewContainer">
                <div className="addAnotherPcView">
                    <img id="closeButton" className="closeButton" src={xIcon} onClick={this.props.onCloseClickedCallback} />
                    <div className="addAnotherPcViewHeader addAnotherPcViewSection">
                        <img className="addAnotherPcViewHeaderImage" id="addAnotherPcViewHeaderImage" src={newPcIcon} />
                        <div className="addAnotherPcViewHeaderText">
                            <h2>Adding and activating a new PC</h2>
                            <p>Follow the steps below to add a new PC to your Driver Support One subscription.</p>
                        </div>
                        <hr />
                    </div>
                    <div className="addAnotherPcViewOptionOne addAnotherPcViewSection">
                        <img className="addAnotherPcViewOptionOneImage" id="addAnotherPcViewOptionOneImage" src={optionOne} />
                        <div className="addAnotherPcViewoptionOneText">
                            <h2>Download and install Driver Support One on your new PC</h2>
                            <p>If you are on your new PC, click the button below to start your download.</p>
                            <a className="downloadNowButton appButton" onClick={this.props.onDownloadClickedCB}>
                                Download Now
                            </a>
                        </div>
                        <hr />
                    </div>
                    <div className="addAnotherPcViewOptionTwo addAnotherPcViewSection">
                        <img className="addAnotherPcViewOptionTwoImage" id="addAnotherPcViewOptionTwoImage" src={optionTwo} />
                        <div className="addAnotherPcViewOptionTwoText">
                            <h2>Login to Driver Support One to register your software</h2>
                            <p>You can login using your email address and your password. If you've forgotten your password you can use
                            the forgot password button below to reset it.</p>
                            <a className="forgotPasswordButton appButton" href={`${authControllerPath}forgotpassword/`}>
                                I forgot my password
                            </a>
                        </div>
                    </div>
                </div>
            </div>;
        } catch (ex) {
            let outerex = new Error(`Error Rendering AddAnotherPcView - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            return null;
        }
    }
}