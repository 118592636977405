import * as React from "react";

export class Footer extends React.Component {
    public render() {
        const year = new Date().getUTCFullYear();
        return <div id="footer">
            <div className="wrapper">
                <div className="dsLogoTiny floatLeft"></div>
                <p className="footerText floatRight copyrightText">Copyright 1996-{year} Driver Support. All rights reserved. DRIVER SUPPORT ONE, the DRIVER SUPPORT ONE logo, and DRIVERSUPPORT.COM are trademarks of Driver Support and its affiliated companies</p>
                <div className="clear"></div>
                <a className="footerLink floatRight" href="https://www.driversupport.com/customer-reviews/" target="_blank">Customer Reviews</a>
                <div className="footerDivider floatRight"></div>
                <a className="footerLink floatRight" href="https://www.driversupport.com/softwareprinciples/" target="_blank">Software Principles</a>
                <div className="footerDivider floatRight"></div>
                <a className="footerLink floatRight" href="https://www.driversupport.com/privacypolicy/" target="_blank">Privacy Policy</a>
                <div className="footerDivider floatRight"></div>
                <a className="footerLink floatRight" href="https://www.driversupport.com/termsandconditions/" target="_blank">Terms And Conditions</a>
                <div className="footerDivider floatRight"></div>
                <a className="footerLink floatRight" href="https://www.driversupport.com/cookiepolicy/" target="_blank">Cookie Policy</a>
                <div className="footerDivider floatRight"></div>
                <a className="footerLink floatRight" href="https://www.driversupport.com/dsone-uninstall-guide/" target="_blank">Uninstall Instructions</a>
            </div>
        </div>;
    }
}