import * as React from 'react';

interface CancelProgressBarProps {
    step: number;
}

export const CancelProgressBar: React.StatelessComponent<CancelProgressBarProps> = (props) => {
    let activeLineClass = "cancelProgressBarActiveLine";
    switch (props.step) {
        case 1:
            activeLineClass = activeLineClass + " step1";
            break;
        case 2:
            activeLineClass = activeLineClass + " step2";
            break;
        case 3:
            activeLineClass = activeLineClass + " step3";
            break;
        case 4:
            activeLineClass = activeLineClass + " step4";
            break;
        case 5:
            activeLineClass = activeLineClass + " step5";
            break;
    }
    return <div className="cancelProgressBar" id="cancelProgressBar">
        <div className="cancelProgressBarLine" id="cancelProgressBarLine"></div>
        <div className={activeLineClass} id="cancelProgressBarActiveLine"></div>
        <div className="cancelProgressStepWrapper" id="cancelProgressStepWrapper">
            <div className="cancelProgressBarStep active" id="cancelProgressStep1">1</div>
            {props.step > 1 ? (
                <div className="cancelProgressBarStep active" id="cancelProgressStep2">2</div>
            ) : (
                <div className="cancelProgressBarStep" id="cancelProgressStep2">2</div>
                )}
            {props.step > 2 ? (
                <div className="cancelProgressBarStep active" id="cancelProgressStep3">3</div>
            ) : (
                <div className="cancelProgressBarStep" id="cancelProgressStep3">3</div>
            )}
            {props.step > 3 ? (
                <div className="cancelProgressBarStep active" id="cancelProgressStep4">4</div>
            ) : (
                <div className="cancelProgressBarStep" id="cancelProgressStep4">4</div>
            )}
            {props.step > 4 ? (
                <div className="cancelProgressBarStep active" id="cancelProgressStep5">5</div>
            ) : (
                <div className="cancelProgressBarStep" id="cancelProgressStep5">5</div>
            )}
        </div>
    </div>;
};