import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { IComponentProps } from "../main/interfaces/IComponentProps";

declare var webAccountRedirectUrl: string;

interface IProductRedirectState {
    secondsRemaining: number;
}

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class ProductRedirectView extends React.Component<Partial<IComponentProps>, IProductRedirectState>
{

    constructor(props) {
        super(props);
        this.state = { secondsRemaining: 10 };

        const countdownMethod = () => { //count down the seconds and redirect
            if (this.state.secondsRemaining > 0) {
                this.setState({ secondsRemaining: this.state.secondsRemaining - 1 })
                setTimeout(countdownMethod, 1000)
            } else {
                window.location.href = webAccountRedirectUrl;
            }
        }
        setTimeout(countdownMethod, 1000)
    }

    public render() {

        return <div className="wrapper">
            <div className="appPanel defaultMaterial productRedirect">
                <h2 className="productRedirectHeader">Redirecting You To Correct Login Page</h2>
                <p className="productRedirectText">The email you provided is associated to a different product.</p>
                <p className="productRedirectText">You will be redirected to the correct product login page in {this.state.secondsRemaining} seconds.</p>
                <p className="productRedirectText">If you are not redirected automatically, <a href={webAccountRedirectUrl}>Login Again Here</a></p>
            </div>
        </div>;
    }

}